
interface AccountData {
  id:number,
  domain_id:string,
  username:string,
  avatar_url:string,
  last_season_elo:number,
  current_elo:number,
  credit:number,
  match_total:number,
  steam_account:string,
  platform_account:string
}
import { ref, computed, onMounted } from 'vue'
import api from '../../utils/api'
import _ from 'lodash'
export default {
  name: "elolist-index",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const accounts = ref<AccountData[]>([])
    const currentSort = ref<string>('id')
    const currentSortDir = ref<string>('asc')
    const pageSize = ref<number>(5)
    const currentPage = ref<number>(1)
    const loading = ref<boolean>(true)
    const processedRecord = ref<number>(0)
    const rankedAccountsCount = ref<number>(0)
    const sortedAccounts = computed(()=>{
      return Object.values(accounts.value).sort((a,b) => {
        let modifier = 1;
        if(currentSortDir.value === 'desc') modifier = -1;
        if(_.get(a, currentSort.value) < _.get(b, currentSort.value)) return -1 * modifier;
        if(_.get(a, currentSort.value) > _.get(b, currentSort.value)) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (currentPage.value-1)*pageSize.value;
        let end = currentPage.value*pageSize.value;
        if(index >= start && index < end) return true;
      });
    })
    const progress = computed(() => {
      return Math.round(processedRecord.value / Object.keys(accounts.value).length * 100)
    })
    const sortby = (s:string) => {
      if(s === currentSort.value) {
        currentSortDir.value = currentSortDir.value==='asc'?'desc':'asc';
      }
      currentSort.value = s;
    }
    const nextPage = () => {
      if((currentPage.value*pageSize.value) < Object.keys(accounts.value).length) currentPage.value++;
    }
    const prevPage = () => {
      if(currentPage.value > 1) currentPage.value--;
    }
    onMounted(() => {
      api<{
        code: number,
        data: {
          data: AccountData[],
          limit: number,
          next_page: number,
          offset: number,
          page: number,
          prev_page: number,
          total_page: number,
          total_record: number
        },
        msg: string
      }>('/account?limit=65535','get')
      .then((accountsData) => {
        let ps = []
        for (let i = 0; i < Object.keys(accountsData.data.data).length; i+=1){
          let id:number = accountsData.data.data[i].id
          accounts.value[id] = accountsData.data.data[i]
          ps.push(new Promise(resolve=>setTimeout(resolve, 100)).then(()=>{
            api<{
              code: number,
              data: AccountData,
              msg: string
            }>(`/account/${id}`,'post')
            .then((accountData) => {
              accounts.value[id].id = accountData.data.id
              accounts.value[id].domain_id = accountData.data.domain_id
              accounts.value[id].username = accountData.data.username
              accounts.value[id].avatar_url = accountData.data.avatar_url
              accounts.value[id].last_season_elo = accountData.data.last_season_elo
              if (!accountData.data.last_season_elo) accounts.value[id].last_season_elo = -1
              accounts.value[id].current_elo = accountData.data.current_elo
              if (!accountData.data.current_elo) accounts.value[id].current_elo = -1
              accounts.value[id].credit = accountData.data.credit
              accounts.value[id].match_total = accountData.data.match_total
              if (accountData.data.match_total >= 5) rankedAccountsCount.value++
              accounts.value[id].steam_account = accountData.data.steam_account
              accounts.value[id].platform_account = accountData.data.platform_account
              processedRecord.value++
            })
            .catch(error => {
              console.log(error)
            })
          }))
        }
        Promise.all(ps).then(()=>{
          loading.value = false;
        });
      })
      .catch(error => {
        console.log(error)
      })
    })
    return {
      accounts,
      currentSort,
      currentSortDir,
      pageSize,
      currentPage,
      loading,
      processedRecord,
      rankedAccountsCount,
      sortedAccounts,
      progress,
      sortby,
      nextPage,
      prevPage
    }
  }
}
