
import "bootstrap/dist/css/bootstrap.min.css"
import {onMounted, ref} from "vue";
import api from "@/utils/api";

export default {
  name: "header-navbar",
  setup(){
    const hostname = ref<string>('')
    onMounted(async () => {
      api<{
        code: number,
        data: string,
        msg: string
      }>('/hostname','get').then(data => {
        hostname.value = data.data
      })
    })
    return {
      hostname
    }
  }
}
