async function api<T> (url: string, method: string, body?: string|undefined|null): Promise<T> {
    return await fetch('/api'+url,{
        body: body,
        method: method,
        headers: {
            'content-type': 'application/json'
        }
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response.json()
    })
}

export default api