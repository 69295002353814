<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">新建账号</div>

          <div class="card-body">
            <form @submit.prevent="create">
              <div class="form-group">
                <label class="form-label" for="domain_id">域ID</label>
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">https://arena.5eplay.com/data/player/</div>
                  </div>
                  <input type="text" v-model="domain_id" class="form-control" id="domain_id" name="domain_id"
                         placeholder="域ID">
                </div>
                <span class="text-danger">{{ domainIdError }}</span>
              </div>
              <div class="form-group">
                <label class="form-label" for="steam_account">STEAM账号</label>
                <input type="text" v-model="steam_account" class="form-control" id="steam_account"
                       name="steam_account"
                       placeholder="STEAM账号">
                <span class="text-danger">{{ steamAccountError }}</span>
              </div>
              <div class="form-group">
                <label class="form-label" for="platform_account">5EWIN账号</label>
                <input type="text" v-model="platform_account" class="form-control" id="platform_account"
                       name="platform_account" placeholder="5EWIN账号">
                <span class="text-danger">{{ platformAccountError }}</span>
              </div>
              <div class="form-group">
                <label class="form-label" for="otp_auth_uri">OTPAUTH URL</label>
                <input type="text" v-model="otp_auth_uri" class="form-control" id="otp_auth_uri" name="otp_auth_uri"
                       placeholder="OTPAUTH URL">
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">确认提交</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {useForm, useField} from 'vee-validate'
import * as yup from 'yup'
import {ref} from 'vue'
import { useRouter } from 'vue-router'
import api from "@/utils/api";

export default {
  name: "elolist-new",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const schema = yup.object({
      domain_id: yup.string().required().label('域ID'),
      steam_account: yup.string().required().label('Steam账号'),
      platform_account: yup.string().required().label('5E账号')
    });
    const {handleSubmit} = useForm({
      validationSchema: schema,
    });
    const {value: domain_id, errorMessage: domainIdError} = useField('domain_id');
    const {value: steam_account, errorMessage: steamAccountError} = useField('steam_account');
    const {value: platform_account, errorMessage: platformAccountError} = useField('platform_account');
    const otp_auth_uri = ref<string>('')

    const router = useRouter()

    const create = handleSubmit(() => {
      api('/account', 'post', JSON.stringify({
        domain_id: domain_id.value,
        steam_account: steam_account.value,
        platform_account: platform_account.value,
        otp_auth_uri: otp_auth_uri.value
      }))
      .then(() => {
        router.push('/elolist')
      })
      .catch(error => {
        console.log(error)
      })
    })

    return {
      domain_id,
      domainIdError,
      steam_account,
      steamAccountError,
      platform_account,
      platformAccountError,
      otp_auth_uri,
      create
    };
  }
}
</script>

<style scoped>

</style>