<template>
  <div class="container">
    <transition name="fade">
      <div v-show="loading">
        <div class="row justify-content-center">
          Loading...
        </div>
        <div class="row justify-content-center">
          <div class="col-6">
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="{ width: progress + '%' }" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{progress}}%</div>
            </div>
          </div>
        </div>
        <div class="space"></div>
      </div>
    </transition>
    <div class="row gy-5">
      <table class="table text-start">
        <thead>
        <tr>
          <th @click="sortby('id')">#</th>
          <th @click="sortby('domain_id')">域ID</th>
          <th @click="sortby('username')">ID</th>
          <th @click="sortby('avatar_url')">头像</th>
          <th @click="sortby('last_season_elo')">上赛季ELO</th>
          <th @click="sortby('current_elo')">本赛季ELO</th>
          <th @click="sortby('credit')">信用分</th>
          <th @click="sortby('match_total')">赛季比赛场次</th>
          <th @click="sortby('steam_account')">STEAM账号</th>
          <th @click="sortby('platform_account')">5ewin账号</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(account) in sortedAccounts" :key="account.id">
          <td>{{ account.id }}</td>
          <td><a :href="'https://arena.5eplay.com/data/player/' + account.domain_id" target="_blank" class="nav-link">{{ account.domain_id }}</a></td>
          <td>{{ account.username }}</td>
          <td><img class="avatar_img" width="40px" height="40px" :src="account.avatar_url"></td>
          <td>{{ account.last_season_elo }}</td>
          <td>{{ account.current_elo }}</td>
          <td>{{ account.credit }}</td>
          <td>{{ account.match_total }}</td>
          <td>{{ account.steam_account }}</td>
          <td>{{ account.platform_account }}</td>
        </tr>
        </tbody>
      </table>
      <div class="row justify-content-center">
        <div class="col-4">已定级账号数：{{ rankedAccountsCount }}</div>
        <div class="col-4">账号总数：{{ Object.keys(accounts).length }}</div>
        <div class="col-4">未定级账号数：{{ Object.keys(accounts).length - rankedAccountsCount }}</div>
      </div>
      <div class="row justify-content-center">
        <div class="col-4">
          <button type="button" class="btn btn-info float-left" @click="prevPage"><font-awesome-icon icon="arrow-left"></font-awesome-icon> 上一页</button>
        </div>
        <div class="col-4">
          <div class="input-group mb-auto">
            <span class="input-group-text">每页显示</span>
            <select v-model="pageSize" @change="currentPage = 1" class="form-control" id="perPageSelector">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div class="col-4">
          <button type="button" class="btn btn-info float-right" @click="nextPage">下一页 <font-awesome-icon icon="arrow-right"></font-awesome-icon></button>
        </div>

      </div>
      <div class="row justify-content-center">
        <router-link to="/elolist/new" class="col-sm-6 btn btn-success btn-block"><font-awesome-icon icon="plus"/> 添加新账号</router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
interface AccountData {
  id:number,
  domain_id:string,
  username:string,
  avatar_url:string,
  last_season_elo:number,
  current_elo:number,
  credit:number,
  match_total:number,
  steam_account:string,
  platform_account:string
}
import { ref, computed, onMounted } from 'vue'
import api from '../../utils/api'
import _ from 'lodash'
export default {
  name: "elolist-index",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const accounts = ref<AccountData[]>([])
    const currentSort = ref<string>('id')
    const currentSortDir = ref<string>('asc')
    const pageSize = ref<number>(5)
    const currentPage = ref<number>(1)
    const loading = ref<boolean>(true)
    const processedRecord = ref<number>(0)
    const rankedAccountsCount = ref<number>(0)
    const sortedAccounts = computed(()=>{
      return Object.values(accounts.value).sort((a,b) => {
        let modifier = 1;
        if(currentSortDir.value === 'desc') modifier = -1;
        if(_.get(a, currentSort.value) < _.get(b, currentSort.value)) return -1 * modifier;
        if(_.get(a, currentSort.value) > _.get(b, currentSort.value)) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (currentPage.value-1)*pageSize.value;
        let end = currentPage.value*pageSize.value;
        if(index >= start && index < end) return true;
      });
    })
    const progress = computed(() => {
      return Math.round(processedRecord.value / Object.keys(accounts.value).length * 100)
    })
    const sortby = (s:string) => {
      if(s === currentSort.value) {
        currentSortDir.value = currentSortDir.value==='asc'?'desc':'asc';
      }
      currentSort.value = s;
    }
    const nextPage = () => {
      if((currentPage.value*pageSize.value) < Object.keys(accounts.value).length) currentPage.value++;
    }
    const prevPage = () => {
      if(currentPage.value > 1) currentPage.value--;
    }
    onMounted(() => {
      api<{
        code: number,
        data: {
          data: AccountData[],
          limit: number,
          next_page: number,
          offset: number,
          page: number,
          prev_page: number,
          total_page: number,
          total_record: number
        },
        msg: string
      }>('/account?limit=65535','get')
      .then((accountsData) => {
        let ps = []
        for (let i = 0; i < Object.keys(accountsData.data.data).length; i+=1){
          let id:number = accountsData.data.data[i].id
          accounts.value[id] = accountsData.data.data[i]
          ps.push(new Promise(resolve=>setTimeout(resolve, 100)).then(()=>{
            api<{
              code: number,
              data: AccountData,
              msg: string
            }>(`/account/${id}`,'post')
            .then((accountData) => {
              accounts.value[id].id = accountData.data.id
              accounts.value[id].domain_id = accountData.data.domain_id
              accounts.value[id].username = accountData.data.username
              accounts.value[id].avatar_url = accountData.data.avatar_url
              accounts.value[id].last_season_elo = accountData.data.last_season_elo
              if (!accountData.data.last_season_elo) accounts.value[id].last_season_elo = -1
              accounts.value[id].current_elo = accountData.data.current_elo
              if (!accountData.data.current_elo) accounts.value[id].current_elo = -1
              accounts.value[id].credit = accountData.data.credit
              accounts.value[id].match_total = accountData.data.match_total
              if (accountData.data.match_total >= 5) rankedAccountsCount.value++
              accounts.value[id].steam_account = accountData.data.steam_account
              accounts.value[id].platform_account = accountData.data.platform_account
              processedRecord.value++
            })
            .catch(error => {
              console.log(error)
            })
          }))
        }
        Promise.all(ps).then(()=>{
          loading.value = false;
        });
      })
      .catch(error => {
        console.log(error)
      })
    })
    return {
      accounts,
      currentSort,
      currentSortDir,
      pageSize,
      currentPage,
      loading,
      processedRecord,
      rankedAccountsCount,
      sortedAccounts,
      progress,
      sortby,
      nextPage,
      prevPage
    }
  }
}
</script>

<style scoped>
.table-otp-img{
  width:40px;
  min-height:20px;
}
th {
  cursor:pointer;
}
td {
  vertical-align: middle;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-active {
  opacity: 0
}
.avatar_img {
  width: 40px;
  height: 40px;
}
body {
  color: #212529;
  background-color: #f5f8fa;
}
</style>