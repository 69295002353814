
import {defineComponent, ref, onMounted} from 'vue';
import api from "@/utils/api";

export default defineComponent({
  name: 'Home',
  setup() {
    const bgv = ref<HTMLVideoElement|null>(null)
    const gabano = ref<string>('')
    const miitno = ref<string>('')
    const hostname = ref<string>('')
    onMounted(async ()=>{
      api<{
        id: number,
        created_at: string,
        updated_at: string,
        deleted_at: string,
        url: string,
        mime: string,
      }>('/background-video','get').then(data => {
        bgv.value && (bgv.value.src = data.url)
        bgv.value && (bgv.value.volume = 0.1)
        bgv.value && (bgv.value.controls = true)
        bgv.value && bgv.value.play()
      })

      api<{
        code: number,
        data: string,
        msg: string
      }>('/miitno','get').then(data => {
        miitno.value = data.data
      })
      api<{
        code: number,
        data: string,
        msg: string
      }>('/gabano','get').then(data => {
        gabano.value = data.data
      })
      api<{
        code: number,
        data: string,
        msg: string
      }>('/hostname','get').then(data => {
        hostname.value = data.data
      })
    })
    return {
      bgv,miitno,gabano,hostname
    }
  }
});
