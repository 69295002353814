<template>
  <div class="flex-center position-ref full-height">
    <div class="fullscreen-bg">
      <video ref="bgv" id="backgorund_video" volume="0.1" controls muted autoplay>
      </video>
    </div>

    <div class="warp">
      <h1>{{ hostname }}</h1>
      <a href="https://github.com/iceking2nd/ielmatch-amxx">ielMatch</a>
      <router-link to="./elolist">ELOList</router-link>
      <router-link to="./download">Downloads</router-link>
      <h4>
        <a v-show="gabano" target="_blank" :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + gabano">
          <img v-show="gabano" src="https://oss.bj.iel.pub/website/images/gongan.png"/>京公网安备 {{ gabano }}号</a>
        <a v-show="miitno" target="_blank" href="https://beian.miit.gov.cn">{{ miitno }}</a>
      </h4>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted} from 'vue';
import api from "@/utils/api";

export default defineComponent({
  name: 'Home',
  setup() {
    const bgv = ref<HTMLVideoElement|null>(null)
    const gabano = ref<string>('')
    const miitno = ref<string>('')
    const hostname = ref<string>('')
    onMounted(async ()=>{
      api<{
        id: number,
        created_at: string,
        updated_at: string,
        deleted_at: string,
        url: string,
        mime: string,
      }>('/background-video','get').then(data => {
        bgv.value && (bgv.value.src = data.url)
        bgv.value && (bgv.value.volume = 0.1)
        bgv.value && (bgv.value.controls = true)
        bgv.value && bgv.value.play()
      })

      api<{
        code: number,
        data: string,
        msg: string
      }>('/miitno','get').then(data => {
        miitno.value = data.data
      })
      api<{
        code: number,
        data: string,
        msg: string
      }>('/gabano','get').then(data => {
        gabano.value = data.data
      })
      api<{
        code: number,
        data: string,
        msg: string
      }>('/hostname','get').then(data => {
        hostname.value = data.data
      })
    })
    return {
      bgv,miitno,gabano,hostname
    }
  }
});
</script>

<style>
.fullscreen-bg {
  position: fixed;
  background-color: #000000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

video {
  width: 100%;
}

.warp::after {
  color: #eeeeee;
  font-family: 'JetBrains Mono', sans-serif;
  font-weight: 100;
  height: 100vh;
  margin: 0;
}

.warp {
  background-color: rgba(255,255,255,0.7);
  margin: 30px auto;
  width: 1200px;
  text-align: center;
  border-radius: 60px;
  position: relative;
  overflow: hidden;
  /*box-shadow: 0 0 12px 3px rgba(0,0,0,0.18);*/
}

.warp h1 {
  font-size: 84px;
  color:#666666;
  text-shadow:0 0 10px rgba(255,255,255,0.2);
}

.warp h4 {
  text-align: center;
  font-size: 12px;
  font-family: "JetBrains Mono","Microsoft Yahei";
}

.warp a {
  color: #666666;
  padding: 0 25px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.wrap::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 60px;
  z-index: -1;
  filter: blur(20px);
  margin: -30px
}

.full-height {
  height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}
</style>