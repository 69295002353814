
import {useForm, useField} from 'vee-validate'
import * as yup from 'yup'
import {ref} from 'vue'
import { useRouter } from 'vue-router'
import api from "@/utils/api";

export default {
  name: "elolist-new",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const schema = yup.object({
      domain_id: yup.string().required().label('域ID'),
      steam_account: yup.string().required().label('Steam账号'),
      platform_account: yup.string().required().label('5E账号')
    });
    const {handleSubmit} = useForm({
      validationSchema: schema,
    });
    const {value: domain_id, errorMessage: domainIdError} = useField('domain_id');
    const {value: steam_account, errorMessage: steamAccountError} = useField('steam_account');
    const {value: platform_account, errorMessage: platformAccountError} = useField('platform_account');
    const otp_auth_uri = ref<string>('')

    const router = useRouter()

    const create = handleSubmit(() => {
      api('/account', 'post', JSON.stringify({
        domain_id: domain_id.value,
        steam_account: steam_account.value,
        platform_account: platform_account.value,
        otp_auth_uri: otp_auth_uri.value
      }))
      .then(() => {
        router.push('/elolist')
      })
      .catch(error => {
        console.log(error)
      })
    })

    return {
      domain_id,
      domainIdError,
      steam_account,
      steamAccountError,
      platform_account,
      platformAccountError,
      otp_auth_uri,
      create
    };
  }
}
