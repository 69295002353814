<template>
  <header class="p-3 mb-3 border-bottom">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
      <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <span class="fs-4">{{ hostname }}</span>
      </a>
      <div class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0"></div>
      <ul class="nav justify-content-center">
        <li>
          <a class="nav-link" href="/elolist">5E ELOList</a>
        </li>
        <li>
          <a class="nav-link" href="/download">Downloads</a>
        </li>
      </ul>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import "bootstrap/dist/css/bootstrap.min.css"
import {onMounted, ref} from "vue";
import api from "@/utils/api";

export default {
  name: "header-navbar",
  setup(){
    const hostname = ref<string>('')
    onMounted(async () => {
      api<{
        code: number,
        data: string,
        msg: string
      }>('/hostname','get').then(data => {
        hostname.value = data.data
      })
    })
    return {
      hostname
    }
  }
}
</script>

<style scoped>
header {
  background-color: #fff;
}
.nav-link {
  color: #212529;
}
</style>